export interface IOperacao{
    operacao: EnumTipoOperacao;
    persistir: EnumPersistir;
  }
  
  export enum EnumTipoOperacao {
      NOVO = 0,
      ALTERAR = 1,
      EXCLUIR = 2,
      NENHUMA = 3,
  }
  
  export enum EnumPersistir {
      SIM = 0,
      NAO = 1,
  } 


  export enum EnumEvento{
      SALVAR = 0,
      FECHAR = 1,
      OUTROS = 9
  }