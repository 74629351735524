import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../componentes/loader.service';
import { PopupAlertService } from '../componentes/popup-alert.service';

const _url = "https://www.mosaicoapp.com.br/mosaicowebapi/api/site/";

@Injectable({
  providedIn: 'root'
})
export class CadastroService {

  constructor( private _http: HttpClient, private loaderservice: LoaderService,
    private popupservice: PopupAlertService) { }

  public _add(cliente: any): Observable<any> {
    return this._http.post(_url + "add", cliente, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  } 


  public Cadastrar(cliente: any): Promise<any> {
    this.loaderservice.show();
    return new Promise(p => {
      this._add(cliente).subscribe(
        (data) => {
          this.loaderservice.hide();
          if (data.status == "OK") {
            p(data.retorno);
          } else {
            this.popupservice.showWarningMessage("Ops!", data.retorno, null);
          }
        },
        (erro) => {
          console.log(erro);
          this.loaderservice.hide();
          this.popupservice.showErrorMessage("Problema", "Atenção ! A operação não pode ser concluída!", null, erro.message);
        },
        () => {
          this.loaderservice.hide();
        }
      );
    });
  }

}


