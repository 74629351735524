import { Directive, Renderer2, ElementRef, OnChanges, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxCpfCnpj } from './ngx-cpf-cnpj';

@Directive({
  selector: '[CpfcnpjMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CpfcnpjMaskDirective),
      multi: true
    }
  ],
  host: {
    // When the user updates the input
    '(input)': 'onInput($event)',
    '(blur)': 'onTouched()',
  },
})
export class CpfcnpjMaskDirective extends DefaultValueAccessor {

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  writeValue(value: any): void {
    const transformed = this.transformValue(value);

    super.writeValue(transformed);
  }

  onInput(e: any): void {
    /*
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toLowerCase();
    e.target.setSelectionRange(start, end);
    */
    e.target.value = NgxCpfCnpj.convertToCpfCnpj(NgxCpfCnpj.getDigitos(e.target.value));

    this.onChange(NgxCpfCnpj.getDigitos(e.target.value));
  }

  private transformValue(value: any): any {
    let result = null;
    if (value)
      result = NgxCpfCnpj.convertToCpfCnpj(NgxCpfCnpj.getDigitos(value));
    /*
    const result = value && typeof value === 'string'
      ? value.toLowerCase()
      : value;
*/
    return result;
  }

  
 
}
