import { Directive, ElementRef, forwardRef, Renderer2, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

const SOLETRA_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SoLetraInputDirective),
  multi: true,
};

//const pattern =  new RegExp('[0-9]','g');

@Directive({
  selector: 'input[soletra]',
  host: {
    // When the user updates the input
    '(input)': 'onInput($event)',
    '(blur)': 'onTouched()',
  },
  providers: [
    SOLETRA_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class SoLetraInputDirective extends DefaultValueAccessor {
  
@Input("soletrapattern") private regex: string = '[0-9]';
@Input("soletratransform") private transform: string = 'none';

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  writeValue(value: any): void {
    const transformed = this.transformValue(value);
    
    super.writeValue(transformed);
  }

  onInput(e: any): void {
    e.target.value = e.target.value.replace(new RegExp(this.regex,'g'), '');
    const transformed = this.transformValue(e.target.value);

    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = transformed;
    e.target.setSelectionRange(start, end);

   // this.onChange(e.target.value);    
   // super.writeValue(transformed);
    this.onChange(transformed);

  }

  private transformValue(value: any): any {
    const result = value && typeof value === 'string' ? this.transform == 'upper'? value.toUpperCase(): this.transform =='lower'? value.toLowerCase(): value : value;
    return result;
    
  }
}