import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSmartModalModule } from 'ngx-smart-modal';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelect2Module } from 'ng-select2';
import { NgxMaskModule } from 'ngx-mask';

import { LowerCaseInputDirective } from './diretivas/lowercaseinput.directive';
import { SoLetraInputDirective } from './diretivas/soletrainput.directive';
import { SoNumeroInputDirective } from './diretivas/sonumeroinput.directive';
import { UpperCaseInputDirective } from './diretivas/uppercaseinput.directive';
import { CpfCnpjValidatorDirective } from './diretivas/cpfcnpj-validator.directive';
import { CpfcnpjMaskDirective } from './diretivas/cpfcnpj-mask.directive';

import { LoaderComponent } from './componentes/loader.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessaoMenutopComponent } from './sessoes/sessao-menutop.component';
import { SessaoRodapeComponent } from './sessoes/sessao-rodape.component';
import { FormCadastroComponent } from './componentes/form-cadastro.component';
import { SessaoModuloComponent } from './sessoes/sessao-modulo.component';
import { SessaoSeunegocioComponent } from './sessoes/sessao-seunegocio.component';
import { SessaoPlanoComponent } from './sessoes/sessao-plano.component';
import { SessaoWaComponent } from './sessoes/sessao-wa.component';
import { SessaoDepoimentoComponent } from './sessoes/sessao-depoimento.component';
import { NoCacheHeadersInterceptor } from './app.funcoes';
import { SessaoIntegracaoComponent } from './sessoes/sessao-integracao.component';
import { SessaoParceirosComponent } from './sessoes/sessao-parceiros.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    SessaoMenutopComponent,
    SessaoRodapeComponent,
    FormCadastroComponent,

    LowerCaseInputDirective,
    SoLetraInputDirective,
    SoNumeroInputDirective,
    UpperCaseInputDirective,
    CpfCnpjValidatorDirective,
    CpfcnpjMaskDirective,
    SessaoModuloComponent,
    SessaoSeunegocioComponent,
    SessaoPlanoComponent,
    SessaoWaComponent,
    SessaoDepoimentoComponent,
    SessaoIntegracaoComponent,
    FormCadastroComponent,
    SessaoParceirosComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgSelect2Module,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent],
  entryComponents: [ LoaderComponent]
})
export class AppModule { }
