import { Injectable, TemplateRef } from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import  Swal from 'sweetalert2';
import { SweetAlertPosition } from 'sweetalert2';
import { SweetAlertIcon } from 'sweetalert2';

@Injectable({  
  providedIn: 'root'
})

export class PopupAlertService {

  constructor(private sal :SweetAlert2LoaderService) { }


  public showInformationMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  

   
    return Swal.fire({
      title: titulo,
      titleText: subtitulo,
      text: texto,
      icon: "info",
      showCancelButton:false,
      allowEscapeKey:false,
      allowOutsideClick:false,
      buttonsStyling: false,

      customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'ml-3 btn btn-danger'
      }   

    });   
  }

  public showSucessMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  



    return Swal.fire({
      title: titulo,
      titleText: subtitulo,
      text: texto,
      icon: "success",
      showCancelButton:false,
      allowEscapeKey:false,
      allowOutsideClick:false,
      buttonsStyling: false,

      customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'ml-3 btn btn-danger'
      }              

    });   
  }

  public showTimerSucessMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  

    

    return Swal.fire({
      title: titulo,
      titleText: subtitulo,
      text: texto,
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
      showCancelButton:false,
      allowEscapeKey:false,
      allowOutsideClick:false,
      buttonsStyling: false,

      customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'ml-3 btn btn-danger'
      } ,
      showClass: {
        popup: 'animate__animated animate__fadeIn'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut'      
      }              

    });   
  }

  public showWarningMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  
   

    return Swal.fire(
      {
        title: titulo,
        titleText: subtitulo,
        text: texto,
        icon: "warning",
        showCancelButton:false,
        allowEscapeKey:false,
        allowOutsideClick:false,
        buttonsStyling: false,
        customClass: {
         
            confirmButton: 'btn btn-primary',
            cancelButton: 'ml-3 btn btn-danger'
        }  ,
        showClass: {
          popup: 'animate__animated animate__fadeIn'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut'      
        }
      });
  }

  public showTimeWarningMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  
    

    return Swal.fire(
      {
        title: titulo,
        titleText: subtitulo,
        text: texto,
        icon: "warning",
        timer: 1500,
        showConfirmButton: false,
        showCancelButton:false,
        allowEscapeKey:false,
        allowOutsideClick:false,
        buttonsStyling: false,
        customClass: {
         
            confirmButton: 'btn btn-primary',
            cancelButton: 'ml-3 btn btn-danger'
        }  ,
        showClass: {
          popup: 'animate__animated animate__fadeIn'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut'      
        }       

      });
  }

  public showErrorMessage(titulo: string, texto: string, subtitulo?: string, footer?: string): Promise<any>{  
   
 
    return  Swal.fire(
      {
        title: titulo,
        titleText: subtitulo,
        text: texto,
        footer: footer,
        icon: "error",
        showCancelButton:false,
        allowEscapeKey:false,
        allowOutsideClick:false,
        buttonsStyling: false,
        showConfirmButton: true,

        confirmButtonText:"Entendi",
        customClass: {
               
                 confirmButton: 'btn btn-primary',
                 cancelButton: 'ml-3 btn btn-danger'
        } ,
        showClass: {
          popup: 'animate__animated animate__fadeIn'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut'      
        }

      });
 
  }

  public showQuestionMessage(titulo: string, texto: string, subtitulo?: string): Promise<any>{  
   

    return Swal.fire({
      title: titulo,
      titleText: subtitulo,
      text: texto,
      icon: "question",
      showCancelButton:true,
      allowEscapeKey:false,
      allowOutsideClick:false,
      buttonsStyling: false,
     
      confirmButtonText:"Confirmar",
      cancelButtonText: "Cancelar",

      customClass: {
        
          confirmButton: 'btn btn-primary',
          cancelButton: 'ml-3 btn btn-danger'
      } ,
      showClass: {
        popup: 'animate__animated animate__fadeIn'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut'      
      }
    });   
  }

  public showToastSucessMessage(texto: string): Promise<any>{  

    
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
    
    return Toast.fire({
      icon: 'success',
      title: texto
    });
    
  }

  
  public showToastMessage(icon: SweetAlertIcon, texto: string , posicao: SweetAlertPosition = "center"): Promise<any>{  

   
    const Toast = Swal.mixin({
      toast: true,
      position: posicao,
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
    
    return Toast.fire({
      icon: 'success',
      title: texto
    });
    
  }

 

}
