import { Component, OnInit ,AfterViewInit,HostListener} from '@angular/core';
import { navAnimateScroll, navSlide } from './app.funcoes';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'sitehotelciawebapp';

  @HostListener('window:scroll',['$event']) onScroll(e: Event): void {
    navSlide();
  }

  ngAfterViewInit(): void {
    navAnimateScroll();
    navSlide();
  }

  ngOnInit(){
    AOS.init();
  }
}
