import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginCadastro } from '../interface/LoginCadastro';
import { CadastroService } from '../servicos/cadastro.service';
import { PopupAlertService } from './popup-alert.service';
import { NavegadorService } from '../servicos/navegador.service';

@Component({
  selector: 'form-cadastro',
  templateUrl: './form-cadastro.component.html',
  styleUrls: ['./form-cadastro.component.scss']
})
export class FormCadastroComponent implements OnInit {

  public meucliente: LoginCadastro ;
  constructor(private cadastroservice: CadastroService,
    private popupservice: PopupAlertService,
    private navegadorservice: NavegadorService,
    private router: Router) { 
     
  }

  ngOnInit() {
    this.meucliente = this.navegadorservice.getDados();
    console.log(this.meucliente);
  }

  Finalizar(){
    this.cadastroservice.Cadastrar(this.meucliente).then((retorno)=>{
      this.popupservice.showSucessMessage("Obrigado!","Seu cadastro foi realizado com sucesso, em breve entraremos em contato com você!");
      this.navegadorservice.Fechar(null);
    });
    
  }
}
