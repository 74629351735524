import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { LoaderComponent } from './loader.component';

var loaderHTML = "<div class='splash sk-loading'>" +
  "<div class='sk-spinner sk-spinner-three-bounce'>" +
  "<div class='sk-bounce1'></div>" +
  "<div class='sk-bounce2'></div>" +
  "<div class='sk-bounce3'></div>" +
  "</div>" +
  "</div>";

  @Injectable({
    providedIn: 'root'
  })
export class LoaderService {
  private _componentRef: any;
  public _isShow: boolean;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) { 
      this._isShow = false;
    }

  public show() {
    if (!this._isShow){
      this.appendComponentToBody(LoaderComponent);
      this._isShow = true;
    }  
  }


  public hide() {
    if(this._isShow){
      this.appRef.detachView(this._componentRef.hostView);
      this._componentRef.destroy();
      this._isShow = false;
    }
  }


  appendComponentToBody(component: any) {
//    console.log(this._componentRef);
  //  if (!this._componentRef) {
      this._componentRef = this.componentFactoryResolver
        .resolveComponentFactory(component)
        .create(this.injector);

      this.appRef.attachView(this._componentRef.hostView);

      const domElem = (this._componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
        
        document.querySelector("body").appendChild(domElem);

     // document.body.appendChild(domElem);
   // }
  }


}
