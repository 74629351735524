import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sessao-modulo',
  templateUrl: './sessao-modulo.component.html',
  styleUrls: ['./sessao-modulo.component.scss']
})
export class SessaoModuloComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
