import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CadastroService } from '../servicos/cadastro.service';
import { NavegadorService } from '../servicos/navegador.service';
import { LoginCadastro } from '../interface/LoginCadastro';
import { EnumPersistir, EnumTipoOperacao } from '../interface/IOperacao';

@Component({
  selector: 'sessao-plano',
  templateUrl: './sessao-plano.component.html',
  styleUrls: ['./sessao-plano.component.scss']
})
export class SessaoPlanoComponent implements OnInit {
  @ViewChild("modalCadastro", { static: false }) modalCadastro: TemplateRef<any>; 
  constructor(private cadastroservice: CadastroService, private navegadorservice: NavegadorService) { }

  ngOnInit() {
  }


  Cadastro(plano: string){
    
    let cad = new LoginCadastro();
    cad.plano = plano;
 
    this.navegadorservice.Criar("Cliente",cad, {operacao: EnumTipoOperacao.NOVO, persistir: EnumPersistir.SIM}).AbrirModal(this.modalCadastro);

  }

}
