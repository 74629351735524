import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LoginCadastro } from '../interface/LoginCadastro';
import { CadastroService } from '../servicos/cadastro.service';
import { NavegadorService } from '../servicos/navegador.service';
import { EnumPersistir, EnumTipoOperacao } from '../interface/IOperacao';


@Component({
  selector: 'sessao-menutop',
  templateUrl: './sessao-menutop.component.html',
  styleUrls: ['./sessao-menutop.component.scss']
})
export class SessaoMenutopComponent implements OnInit {
  @ViewChild("modalCadastro", { static: false }) modalCadastro: TemplateRef<any>; 
  constructor(private cadastroservice: CadastroService, private navegadorservice: NavegadorService) { }

  ngOnInit() {
   
  }

  Entrar(){
    window.open('https://app.mosaicoapp.com.br', '_blank');
  }

  Cadastrar(){

    let cad = new LoginCadastro();
    cad.plano = "MASTER";
 
    this.navegadorservice.Criar("Cliente",cad, {operacao: EnumTipoOperacao.NOVO, persistir: EnumPersistir.SIM}).AbrirModal(this.modalCadastro);
  }

}
