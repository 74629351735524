import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sessao-parceiros',
  templateUrl: './sessao-parceiros.component.html',
  styleUrls: ['./sessao-parceiros.component.scss']
})
export class SessaoParceirosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
