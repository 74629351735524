
declare var jQuery: any;

export function navSlide() {
   
    var nav_header = jQuery('#topbar');
    var header_height = jQuery('#topbar').height() + 500;

    var scroll_top = jQuery(window).scrollTop();

    if (scroll_top >= header_height) { // the detection!
       // jQuery('#topbar').addClass('topbar-background-white');
        jQuery('#topbar').addClass('fixed-top');
        jQuery('#topbar').addClass('menu-top-verde');
        jQuery('#topbar').removeClass('menu-top-branco');

    } else {
      //  jQuery('#topbar').removeClass('topbar-background-white');
        jQuery('#topbar').removeClass('fixed-top');
        jQuery('#topbar').addClass('menu-top-branco');
        jQuery('#topbar').removeClass('menu-top-verde');


    }
    
}

export function navAnimateScroll(){
   
   jQuery('a.nav-link, a.nav-btn-link').on('click', function(e) {
      e.preventDefault();
      jQuery('html, body').animate({
         scrollTop: jQuery(this.hash).offset().top - jQuery('#topbar').height() - 20
      }, 1000);
   });
  
}


import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}


