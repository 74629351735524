import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';

import { NgxSmartModalService } from 'ngx-smart-modal';
import { INgxSmartModalOptions } from 'ngx-smart-modal/src/config/ngx-smart-modal.config';
import { Content } from 'ngx-smart-modal/src/services/ngx-smart-modal.service';
import { Observable, Subscription } from 'rxjs';
import { EnumPersistir, EnumTipoOperacao, IOperacao } from '../interface/IOperacao';



@Injectable({
  providedIn: 'root'
})
export class NavegadorService {
  private subscription: Subscription;

  public action: Observable<any> = new Observable<any>();
  public onRedirect: EventEmitter<any> = new EventEmitter();
  public onChangeData: EventEmitter<any> = new EventEmitter();
  private stackedDialogs: Array<DialogoStack> = [];

  constructor(private routerService: Router, 
    private ngxSmartModalService: NgxSmartModalService, 
    private activatedRouteService: ActivatedRoute) { 

     
    }


    Fechar(route: ActivatedRoute) {
      if (this.ngxSmartModalService.getOpenedModals().length > 0) {
        let modal = this.ngxSmartModalService.getTopOpenedModal();
        if (modal !== undefined && modal !== null) {
          modal.close();
        //  let index = this.dialogoStack.findIndex(p=>p.id == modal.identifier);
          this.ngxSmartModalService.removeModal(modal.identifier);   
        }
      }else{
          this.redirecionar(route); 
      }
      this.stackedDialogs.splice(0, 1);
    }

    FecharTodos(){

      //this.ngxSmartModalService.getOpenedModals().forEach(modal => {
       // let index = this.dialogoStack.findIndex(p=>p.id == modal.id);
       // if (index >=0)
        //  this.dialogoStack.splice(index, 1);
     // });
      this.stackedDialogs = [];
      this.ngxSmartModalService.closeAll();   
    }


    public Criar(id: string,
      dados?: any,
      operacao?: IOperacao,
      redirecionarCallBack?: Function,
      actionCallBack?: Function): Dialogo {
        let data = new DialogoData(id, dados, operacao, redirecionarCallBack, actionCallBack);
        let dialogo = new Dialogo(id, this.ngxSmartModalService,this.routerService);
        let stack = new DialogoStack(id, dialogo, data);
         this.stackedDialogs.unshift(stack);

         
          return dialogo;
    }


    public getDados(datadefault: any = null, redirecionar: string = "/home"): any {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      let p = this.stackedDialogs[0].dialogodata;
      if (p != null && p !== undefined)
        return p.dados;
      else 
      {
        if (datadefault != null && datadefault !== undefined)
          return datadefault;
        else 
        { 
          this.routerService.navigate([redirecionar]);
          return;
        }
      }
    }
  
    public getID(): string {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      let p = this.stackedDialogs[0].dialogodata;
      if (p != null)
        return p.id;
      else
        return "";
    }
  
    public updateDados(dados: any) {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      let p = this.stackedDialogs[0].dialogodata;
      if (p != null && p != undefined) {
        p.dados = dados;
        this.onChangeData.next(dados);
      }
    }

    public getOperacao(datadefault: IOperacao = {operacao: EnumTipoOperacao.NOVO,persistir: EnumPersistir.SIM}) {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      let p = this.stackedDialogs[0].dialogodata;
      if (p != null)
        return p.operacao;
      else
        return datadefault;
    }

    private redirecionar(route) {
      //let p = this.dialogoStack[index].dialogodata;
      if (this.stackedDialogs){
        let p = this.stackedDialogs[0].dialogodata;
        if (p != null && p !== undefined  && p.redirecionarCallBack != null) {
          p.redirecionarCallBack(route);
        }
      }

     // console.log(this.activatedRouteService);
  
    }
  

    public podeRedirecionar(): boolean {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      if (this.stackedDialogs){
        let p = this.stackedDialogs[0].dialogodata;
        return p != null && p !== undefined && p.redirecionarCallBack !== null;
      }else
        return false;
    }
   
  
    public EnviarAcao(acao: any) {
      //let p = this.dialogoStack[this.dialogoStack.length -1].dialogodata
      if (this.stackedDialogs){
        let p = this.stackedDialogs[0].dialogodata;
        if (p != null && p !== undefined && p.actionCallBack != null) {
          p.actionCallBack(acao);
        }
      }
    }

   

}


class Dialogo {
  private _id: string;
  
  public Abrir(any){
    //this.ngxSmartModalService.closeAll();
  }

  public AbrirModal(modal:Content<any>, options?: INgxSmartModalOptions){
    let opt: INgxSmartModalOptions = {
      closable:  false,
      escapable: true,
      dismissable: false,
      customClass:  "dialog-auto nsm-centered",
      backdrop:  true,
      force:  false
    }
    
    if(options !== undefined){
      opt =  {
              closable: options.closable !== undefined ? options.closable: false,
              escapable: options.escapable !== undefined ? options.escapable: true,
              dismissable: options.dismissable !== undefined ? options.dismissable : false,
              customClass: options.customClass !== undefined ? options.customClass: "dialog-auto nsm-centered",
              backdrop: options.backdrop !== undefined ? options.backdrop :  true,
              force:  options.force !== undefined ? options.force:  false
            }
    }
    this.ngxSmartModalService.create(this._id, modal, opt).open();

  }

 
  constructor(public id: string,
    private ngxSmartModalService: NgxSmartModalService, 
    private routeservice: Router){
    this._id = id;
  }

}


class DialogoData {
  public id: string;
  public operacao?: IOperacao | null;
  public dados?: any;
  public redirecionarCallBack?: Function;
  public actionCallBack?: Function;
  constructor(id: string, data: any, oper: IOperacao, redirecionarCallBack: Function, actionCallBack: Function) {
    this.id = id;
    this.operacao = oper;
    this.dados = data;
    this.redirecionarCallBack = redirecionarCallBack;
    this.actionCallBack = actionCallBack;
  }
}

class DialogoStack{
    public id: string;
    public dialogo: Dialogo;
    public dialogodata: DialogoData;

    constructor(id: string, dialogo: Dialogo, data: DialogoData){
      this.id = id;
      this.dialogo = dialogo;
      this.dialogodata = data;
    }
}
