import { Directive, ElementRef, forwardRef, Renderer2, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

const SONUMERO_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SoNumeroInputDirective),
  multi: true,
};

@Directive({
  selector: 'input[sonumero]',
  host: {
    // When the user updates the input
    '(input)': 'onInput($event.target.value)',
    '(blur)': 'onTouched()',
  },
  providers: [
    SONUMERO_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class SoNumeroInputDirective extends DefaultValueAccessor {
  
  @Input("sonumeropattern") private regex: string = '[a-zA-Z]';

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  writeValue(value: any): void {
    const transformed = this.transformValue(value);
    
    super.writeValue(transformed);
  }

  onInput(value: any): void {
    value = value.replace(new RegExp(this.regex,'g'), '');
    const transformed = this.transformValue(value);

    super.writeValue(transformed);
    this.onChange(transformed);

  }

  private transformValue(value: any): any {
    const result = value && typeof value === 'string' ? value.toUpperCase(): value;
    return result;
    
  }
}