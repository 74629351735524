export class LoginCadastro {
    public cnpj: string;
    public razaosocial: string;
    public nome: string;
    public plano: string;
    public telefone: string;
    public email: string;
    public senha: string;
   
    constructor() {
     
    }
  }