import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sessao-integracao',
  templateUrl: './sessao-integracao.component.html',
  styleUrls: ['./sessao-integracao.component.scss']
})
export class SessaoIntegracaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
